.login-screen {
  background-image: url('@/assets/img/img_1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;

  .cover {
    background-color: #000;
    height: 100%;
    left: 0;
    opacity: 0.2;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  .wrapper {
    height: 100%;

    .logo {
      line-height: 0;
      margin-bottom: 20px;
      text-align: center;
    }

    .alert-container {
      margin-bottom: 20px;
    }

    .ant-card {
      padding: 0px;
    }

    img {
      height: auto;
      max-width: 200px;
      width: 85%;
    }
  }
}
